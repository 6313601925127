@import '~normalize.css';
@import 'swiper/css/bundle';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
@font-face
{
    font-family: 'Material Icons';
    font-style : normal;
    font-weight: 400;
    src        : url('../src/assets/fonts/iconfont.woff2') format('woff2');
}
.iconfont
{
    font-family                  : 'Material Icons';
    font-weight                  : normal;
    font-style                   : normal;
    font-size                    : 24px;
    line-height                  : 1;
    letter-spacing               : normal;
    text-transform               : none;
    display                      : inline-block;
    white-space                  : nowrap;
    word-wrap                    : normal;
    direction                    : ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing       : antialiased;
}

// general css
body
{
    background : #f1f1f9;
    color      : #444878;
    font-family: 'Source Sans Pro', sans-serif;;
}
.swiper-slide.auto
{
    width: auto !important;
}
.clear-row
{
    display : block;
    position: relative;
    width   : 100%;
    overflow: hidden;
    float   : none;
    clear   : both;
}

.NgxEditor
{
    border:none !important;

    & > div
    {
        border-top:solid 1px #d8d9e4;
        padding   : 12px;
        min-height: 300px;
        font-size : 14px;
    }
}